import * as React from 'react';

function SvgStarbox(props) {
  return (
    <svg viewBox="0 0 46 46" fill="none" {...props}>
      <rect width={46} height={46} rx={2} fill="#8CC63F" />
      <path
        d="M22.994 12l3.71 7.24L35 20.4l-5.997 5.648L30.413 34l-7.419-3.754L15.587 34l1.41-7.953L11 20.399l8.297-1.159L22.994 12z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgStarbox;
