import React from 'react';
import { SEO, HomePageAndRulesHero, AboutCampaignX, TheResult, OverviewOrRulesVideo } from 'components';
import styled from 'styled-components';
import XsAndOs2 from 'images/Home/XsAndOs2';
import XsAndOs3 from 'images/Home/XsAndOs3';
import XsAndOs4 from 'images/Home/XsAndOs4';
import Starbox from 'images/Rules/Starbox';

const Rules = ({ scrollToAnchor, location, user, game, initialClientRender }) => (
  <Wrapper>
    <SEO pageSpecificTitle="Rules" />
    <HomePageAndRulesHero
      h1Text="Campaign X<br />what it is and how it works"
      h3Text="The Rules of Engagement"
      scrollToAnchor={scrollToAnchor}
      pathname={location.pathname}
      initialClientRender={initialClientRender}
    />
    <div id="find-out-more" />
    {user && game && <OverviewOrRulesVideo videoCode="440697948" />}
    <InnerWrapper>
      <AboutCampaignX />
      <TheResult />
      <Text>
        <h3>So, how does it work?</h3>
        <div>
          <GreenBox />
          <div>
            There are ten challenge levels, spread over a number of weeks.
            <br />
            <br />
            <ul>
              Each level contains two core activities:
              <li>
                <GreyBox />A strategy challenge
              </li>
              <li>
                <GreyBox />
                And a team play experience
              </li>
            </ul>
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <GreenBox />
          <div>
            All players are required to collaborate to complete both activities before proceeding to
            subsequent levels.
          </div>
          <StyledXsAndOs3 />
        </div>
        <div>
          <GreenBox />
          <div>
            Each level will have a full set of instructions including support materials, start and
            finish times.
          </div>
        </div>
        <div>
          <GreenBox />
          <div>
            During each level, the agency will facilitate all challenges via web conferencing and
            the game app as required.
          </div>
        </div>
        <div
          style={{
            marginTop: '0.4em',
            display: 'flex',
            alignItems: 'center',
            position: 'relative'
          }}>
          <StyledStarbox />
          <div>Additional support is available at support@campaign-x.com</div>
          <StyledXsAndOs2 />
        </div>
        <h3>Points and Prizes</h3>
        <p>
          But wait, there’s more! There are points to accumulate and prizes to be won!
          <br />
          <br />
          Every level has a certain number of points available, depending on the degree of
          difficulty.
          <br />
          <br />
          The gamemaster shares a live scoreboard, updating per activity, per team, thus keeping
          that competitive spirit alive throughout!
        </p>
        <br />
        <br />
        <div>
          <GreenBox />
          <div>Points are awarded based on the quality of the work completed.</div>
        </div>
        <p>
          They will accumulate at each level all the way to Level X where we will reveal the
          undisputed, most excellent, most revered Campaign X champions!
        </p>
        <br />
        <br />
        <p>
          <b>
            And most importantly, the final campaign that you all created ready to launch to the
            market!
          </b>
        </p>
      </Text>
    </InnerWrapper>
    <InnerWrapper>
      <MoreThanACompetition>
        <p>It&apos;s more than a competition!</p>
        <h3>Compete and Collaborate</h3>
        <p>for best in class results</p>
      </MoreThanACompetition>
    </InnerWrapper>
    <div>
      <InnerWrapper>
        <Text>
          <h3>What players need to do to get started:</h3>
          <div>
            <GreenBox />
            <div>Register and provide the contact details as requested by their team leader</div>
          </div>
          <div>
            <GreenBox />
            <div>Have access to company- approved web conferencing software</div>
          </div>
          <div>
            <GreenBox />
            <div>Check that their internet connection is good enough for web conferencing</div>
          </div>
          <div>
            <GreenBox />
            <div>
              Download the Campaign X Game app to their smart phone-(details will be provided)
            </div>
          </div>
          <h3>Let the games begin!</h3>
        </Text>
      </InnerWrapper>
      <StyledXsAndOs4 />
    </div>
  </Wrapper>
);

const GreenBox = styled.div`
  background-color: var(--cx-dark-green);
  border-radius: 0.125em;
  display: inline-block;
  flex-shrink: 0;
  position: relative;
  top: 0.4em;
  width: 0.5rem;
  height: 0.5rem;

  @media screen and (min-width: 768px) {
    width: 1rem;
    height: 1rem;
  }
`;

const GreyBox = styled.div`
  background-color: var(--cx-dark-beige);
  border-radius: 0.125em;
  bottom: 0.175em;
  display: inline-block;
  height: 0.375rem;
  margin-left: 1.25rem;
  margin-right: 0.75em;
  position: relative;
  width: 0.375rem;

  @media screen and (min-width: 768px) {
    height: 0.625rem;
    width: 0.625rem;
    bottom: 0.125em;
    margin-left: 1.5rem;
  }
`;

const InnerWrapper = styled.div`
  max-width: 90rem;
  margin: 0 0.625rem;

  @media screen and (min-width: 768px) {
    margin: 0 9.1%;
  }
`;

const MoreThanACompetition = styled.div`
  align-items: center;
  background-color: var(--cx-beige);
  display: flex;
  flex-direction: column;
  font-weight: 700;
  height: 16.25rem;
  justify-content: center;
  position: relative;
  text-align: center;
  top: 8.125rem;
  padding: 0 0.5rem;
  width: calc(100% + 1.25rem);
  right: 0.625rem;
  z-index: 1;

  @media screen and (min-width: 550px) {
    width: 100%;
    right: 0;
  }

  p {
    color: #000;
    font-size: 2.75rem;
    line-height: 1.208em;

    @media screen and (min-width: 360px) {
      font-size: 3rem;
    }

    &:last-of-type {
      font-size: 1.5rem;
      line-height: 1.219em;
      margin-top: 0.1em;

      @media screen and (min-width: 550px) {
        font-size: 2rem;
      }
    }
  }

  h3 {
    color: var(--cx-dark-green);
    letter-spacing: 0.1em;
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 1.219em;
    margin-top: 0.4em;

    @media screen and (min-width: 550px) {
      font-size: 2rem;
    }
  }
`;

const Text = styled.div`
  max-width: 58.25em;
  margin: 0 auto;

  h3 {
    font-size: 1.5rem;
  }

  > p {
    font-size: 1.125rem;
  }

  > div {
    align-items: flex-start;
    display: inline-flex;
    margin-bottom: 2.25rem;

    > div:last-of-type {
      font-size: 1.125rem;
      margin-left: 1em;

      @media screen and (min-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }

  ul {
    list-style-type: none;
  }

  @media screen and (min-width: 768px) {
    h3 {
      font-size: 2rem;
    }
  }
`;

const Wrapper = styled.div`
  > div:nth-of-type(2) ${Text} {
    margin-bottom: -2.1em;

    h3 {
      /* margin: 2em 0 1.5em; */
      margin: 2.9em 0 1.5em;
    }
  }

  > div:last-of-type {
    background-color: rgba(239, 237, 228, 0.3);
    padding: 12.85em 0 7.7em;
    position: relative;

    ${Text} {
      h3 {
        &:nth-of-type(1) {
          margin-bottom: 1.75em;
        }

        &:nth-of-type(2) {
          color: var(--cx-dark-green);
          margin-top: 2.075em;
          text-align: center;
        }
      }
    }
  }
`;

const StyledXsAndOs2 = styled(XsAndOs2)`
  bottom: -15em;
  right: -14em;
  position: absolute;
  width: 15em;
`;

const StyledXsAndOs3 = styled(XsAndOs3)`
  left: -13.5rem;
  position: absolute;
  top: 1.3rem;
  width: 16.563rem;
`;

const StyledXsAndOs4 = styled(XsAndOs4)`
  position: absolute;
  right: 0;
  top: 11.75em;
  width: 32.5em;
`;

const StyledStarbox = styled(Starbox)`
  width: 2.875rem;
  height: 2.875rem;
`;

export default Rules;
